import React from 'react';

import { Container, Content } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import CustomerFeedbacks from '../../components/home/CustomerFeedbacks';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';

const CustomerFeedbacksPage = () => (
  <Layout routeSlug="CustomerFeedbacksPage">
    <Container>
      <MenuBar />
      <Content>
        <CustomerFeedbacks />
      </Content>
      <Footer />
    </Container>
  </Layout>
);

export default CustomerFeedbacksPage;
